import React, { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import "../css/AdminDataInput.css";
import { useNavigate, useParams } from "react-router-dom";
export const EditPost = () => {
  // const [postType, setPostType] = useState (null)
  const { id } = useParams();
  const navigator = useNavigate()
  console.log(id);
  const [form, setForm] = useState({});
  const [img, setImg] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (form.image) {
      const storageRef = storage.ref();
      const file1Ref = storageRef
        .child(`images/${form.image.name}`)
        .put(form.image);
      file1Ref.on(
        "state_changed",
        function (snapshot) {},
        function (error) {},
        function () {
          file1Ref.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            setForm({ ...form, image: downloadURL });
            db.collection("posts")
              .doc(id)
              .update({ ...form, image: downloadURL })
              .then((res) => {
                setLoader(false);
                setError("Post Updated");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          });
        }
      );
    } else {
      db.collection("posts")
        .add(form)
        .then((res) => {
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deletePost=()=>{
    console.log("clicked",id)
    db.collection('posts').doc(id).delete().then(item=>{
        setError('Item Deleted')
        navigator('/posts')
    }).catch(err=>{
        console.log('err',err)
    })
  }

  useEffect(() => {
    setLoader(true);
    db.collection("posts")
      .doc(id)
      .get()
      .then((item) => {
        setForm(item.data());
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        setError("Error in fetching data");
      });
  }, []);

  return (
    <div id="container">
      <form onSubmit={handleSubmit} className="form" id="form">
        {error && <p>{error}</p>}
        <div className="form-item">
          <label>Category</label>
          <select
            onChange={(e) => setForm({ ...form, type: e.target.value })}
            value={form.type}
          >
            <option value="bird eye view">Bird Eye View</option>
            <option value="properties">Properties</option>

            <option value="events">Events</option>
            <option value="devotional">Devotional</option>

            <option value="obituaries">Obituaries</option>
          </select>
        </div>
        <div className="form-item">
          <label>Title</label>
          <input
            type="text"
            required
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            value={form.title}
          />
        </div>
        <div className="form-item">
          <label>Description</label>
          <textarea
            type="text"
            required
            onChange={(e) => setForm({ ...form, desciption: e.target.value })}
            value={form.desciption}
          />
        </div>
        <div className="form-item">
          <label>Video Link (optional)</label>
          <input
            type="text"
            onChange={(e) => setForm({ ...form, videoLink: e.target.value })}
            value={form.videoLink}
          />
        </div>
        <div className="form-item">
          <label>Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setForm({ ...form, image: e.target.files[0] })}
            // value={form.image}
          />
          {/* {img && <img src={img} width='200' height='auto'/>} */}
        </div>

        {form.type == "bird eye view" && (
          <>
            <div className="form-item">
              <label>Start Date</label>
              <input
                type="date"
                required
                onChange={(e) =>
                  setForm({ ...form, startDate: e.target.value })
                }
                value={form.startDate}
              />
            </div>
            <div className="form-item">
              <label>End Date</label>
              <input
                type="date"
                required
                onChange={(e) => setForm({ ...form, endDate: e.target.value })}
                value={form.endDate}
              />
            </div>
            <div className="form-item">
              <label>Market Place</label>
              <input
                type="text"
                required
                onChange={(e) =>
                  setForm({ ...form, marketPlace: e.target.value })
                }
                value={form.marketPlace}
              />
            </div>
          </>
        )}

        {form.type == "properties" && (
          <>
            <div className="form-item">
              <label>address</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, address: e.target.value })}
                value={form.address}
              />
            </div>
            <div className="form-item">
              <label>Rent/Price</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, rent: e.target.value })}
                value={form.rent}
              />
            </div>
            <div className="form-item">
              <label>Landmark</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, landmark: e.target.value })}
                value={form.landmark}
              />
            </div>
            <div className="form-item">
              <label>More info</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, moreInfo: e.target.value })}
                value={form.moreInfo}
              />
            </div>
          </>
        )}
        {form.type == "events" && (
          <>
            <div className="form-item">
              <label>Start Date</label>
              <input
                type="date"
                required
                onChange={(e) =>
                  setForm({ ...form, startDate: e.target.value })
                }
                value={form.startDate}
              />
            </div>
            <div className="form-item">
              <label>End Date</label>
              <input
                type="date"
                required
                onChange={(e) => setForm({ ...form, endDate: e.target.value })}
                value={form.endDate}
              />
            </div>
            <div className="form-item">
              <label>Place</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, place: e.target.value })}
                value={form.place}
              />
            </div>
            <div className="form-item">
              <label>Time</label>
              <input
                type="time"
                required
                onChange={(e) => setForm({ ...form, time: e.target.value })}
                value={form.time}
              />
            </div>
            <div className="form-item">
              <label>Occasion</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, occasion: e.target.value })}
                value={form.occasion}
              />
            </div>
            <div className="form-item">
              <label>Contact</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, contact: e.target.value })}
                value={form.contact}
              />
            </div>
          </>
        )}
        {form.type == "devotional" && (
          <>
            <div className="form-item">
              <label>Date</label>
              <input
                type="date"
                required
                onChange={(e) =>
                  setForm({ ...form, startDate: e.target.value })
                }
                value={form.startDate}
              />
            </div>

            <div className="form-item">
              <label>Today's Word</label>
              <input
                type="text"
                required
                onChange={(e) =>
                  setForm({ ...form, todayWord: e.target.value })
                }
                value={form.todayWord}
              />
            </div>
            <div className="form-item">
              <label>Quote of the day</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, quote: e.target.value })}
                value={form.quote}
              />
            </div>
            <div className="form-item">
              <label>Devotion</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, devotion: e.target.value })}
                value={form.devotion}
              />
            </div>
            <div className="form-item">
              <label>Prayer</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, prayer: e.target.value })}
                value={form.prayer}
              />
            </div>
          </>
        )}
        {form.type == "obituaries" && (
          <>
            <div className="form-item">
              <label>Date</label>
              <input
                type="date"
                required
                onChange={(e) =>
                  setForm({ ...form, startDate: e.target.value })
                }
                value={form.startDate}
              />
            </div>
            <div className="form-item">
              <label>Name</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                value={form.name}
              />
            </div>
            <div className="form-item">
              <label>Contact</label>
              <input
                type="text"
                required
                onChange={(e) => setForm({ ...form, contact: e.target.value })}
                value={form.contact}
              />
            </div>
          </>
        )}

        <button type="submit" disabled={loader}>
          {loader ? "Sending" : "Update"}
        </button>
        <button onClick={()=>deletePost()} disabled={loader}>
         Delete
        </button>
      </form>
    </div>
  );
};
