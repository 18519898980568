import React, { useEffect, useState } from 'react'
import CardTopFour from '../components/CardTopFour'
import "../css/homepage.css"
import { db } from '../firebase'

const Homepage = () => {
  const [items,setItems] = useState({birdEyeView:[],devotional:[],properties:[],events:[],obituaries:[]})

  useEffect(()=>{
    let birdView=[]
    let devo =[]
    let prop=[]
    let events=[]
    let obituaries=[]
    db.collection('posts').get().then(data=>{
      data.forEach(item=>{
        let itemData = item.data()
        let id =item.id
        switch (itemData.type){
          case 'bird eye view':
            birdView.push({id,itemData})
            break;
            case 'properties':
              prop.push({id,itemData})
              break;
              case 'events':
                events.push({id,itemData})
                break;
                case 'devotional':
                  devo.push({id,itemData})
                  break;
                  
                  case 'obituaries':
                    obituaries.push({id,itemData})
                  break;
                  default:
                    break;

        }

      })
      setItems({properties:prop.slice(0,4),devotional:devo.slice(0,4),obituaries:obituaries.slice(0,4),events:events.slice(0,4),birdEyeView:birdView.slice(0,4)})
    })
  },[])
  return (
      <div className="homepage">
<div className="container-aboutus">
  <h1 className="aboutus-title">About Us</h1>
  <div style={{textAlign:"center"}}>
  <p className="aboutus-content">Welcome to Panoramic for us website. It’s a website for us by us. It is intended to promote socio- economic welfare of the Mwanainchi. It accommodates a wide range of issues involving the people of Kenya far and near but with a common view.</p>
  </div>
</div>
<h1 className="aboutus-title">Bird Eye View</h1>
<div className="CardTopFour">
{items.birdEyeView.length>0?items.birdEyeView.map((item,index)=>{
   return <CardTopFour key={index} item={item}/>
  }):<p>No Post Found</p>}

</div>



{/* <div className="container-aboutus">
  <h1 className="aboutus-title">Birds eye view</h1>
  <div style={{textAlign:"center"}}>
    <p className="aboutus-content">9th Africities Summit</p>
  <p className="aboutus-content">For the first time ever, Kisumu city in Kenya was hosting the 9th edition of Africities Summit from 17th to 21st May 2022. The theme for the edition was “ The role of intermediary Cities of Africa in the implementation of Agenda2030 of the United Nations and African Union Agenda 2063”. Lupita Nyong’o was the goodwill ambassador.</p>
  </div>
</div> */}

    
{/* <div className="container-aboutus">
  <h1 className="aboutus-title">Birds eye view</h1>
  <div style={{textAlign:"center"}}>
    <p className="aboutus-content">9th Africities Summit</p>
  <p className="aboutus-content">For the first time ever, Kisumu city in Kenya was hosting the 9th edition of Africities Summit from 17th to 21st May 2022. The theme for the edition was “ The role of intermediary Cities of Africa in the implementation of Agenda2030 of the United Nations and African Union Agenda 2063”. Lupita Nyong’o was the goodwill ambassador.</p>
  </div>
</div> */}
<h1 className="aboutus-title">Events</h1>
<div className="CardTopFour">

{items.events.length>0?items.events.map((item,index)=>{
   return <CardTopFour key={index} item={item}/>
  }):<p>No Post Found</p>}

</div>

<h1 className="aboutus-title">Properties</h1>
<div className="CardTopFour">

{items.properties.length>0?items.properties.map((item,index)=>{
   return <CardTopFour key={index} item={item}/>
  }):<p>No Post Found</p>}

</div>

<h1 className="aboutus-title">Devotional</h1>
<div className="CardTopFour">

{items.devotional.length>0?items.devotional.map((item,index)=>{
   return <CardTopFour key={index} item={item}/>
  }):<p>No Post Found</p>}

</div>

<h1 className="aboutus-title">Obituaries</h1>
<div className="CardTopFour">

  {items.obituaries.length>0?items.obituaries.map((item,index)=>{
   return <CardTopFour key={index} item={item}/>
  }):<p>No Post Found</p>}

</div>
    </div>
  )
}

export default Homepage