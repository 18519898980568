export const config = {
  apiKey: "AIzaSyBQ8ZlugUMRm9vkbvd3ISGA_XRFPwqmsyo",
  authDomain: "panoramic-f86ac.firebaseapp.com",
  projectId: "panoramic-f86ac",
  storageBucket: "panoramic-f86ac.appspot.com",
  messagingSenderId: "823924644293",
  appId: "1:823924644293:web:87c0ea74d27368355c53ac",
  measurementId: "G-V2HZE4ZWYN",
  serviceId:'service_47aghj2',
  templateId:'template_daofey6',
  publicKey:'iIqw3-3io0Eo6u0Nn'
  };
  