// import firebase from "firebase";
// import "firebase/auth";
// import "firebase/firestore"
// import 'firebase/storage'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { config } from "./config";

const app = firebase.initializeApp({
    apiKey: config.apiKey,
    authDomain: config.authDomain,
    projectId: config.projectId,
    storageBucket: config.storageBucket,
    messagingSenderId: config.messagingSenderId,
    appId:config.appId,
    measurementId: config.measurementId
});

export const auth = app.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export default app;


// export { app };
