import React from 'react'
import {Card, Button} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const PageCard = ({item}) => {

  return (<>
  
  <Card style={{margin:"10px",width:"60vw"}} >
    <Card.Img variant="top" style={{width:"100%"}} src={item.item.image} />
    <Card.Body>
    <Card.Title>
       <Link to={`/post?id=${item.id}`} style={{textDecoration:'none'}}>{item.item.title}</Link> 
      </Card.Title>
      <Card.Text>
        {item.item.desciption}
      </Card.Text>
    </Card.Body>
  </Card>
  </>
  )
}

export default PageCard