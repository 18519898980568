import React, { useState } from "react";
import { auth } from "../firebase";
const Register = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(credentials)
    auth
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((user) => {
        console.log(user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">Email</label>
      <input
        type="email"
        required
        name="email"
        onChange={(e) =>
          setCredentials({ ...credentials, email: e.target.value })
        }
      />
      <label htmlFor="password">Password</label>
      <input
        type="password"
        required
        name="password"
        onChange={(e) =>
          setCredentials({ ...credentials, password: e.target.value })
        }
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default Register;
