import React, { useState } from 'react'
import "../css/Footer.css"
import emailjs from '@emailjs/browser';
import { config } from '../config';
import {Link} from "react-router-dom"
const Footer = () => {
    const [mail,setMail]=useState({email:'',message:''})
    const [error,setError] =useState(null)
    const sendEmail =async(e)=>{
        e.preventDefault()
emailjs.send(config.serviceId,config.templateId,{email:mail.email,message:mail.message},config.publicKey).then(function(response) {
    return setError(response.text), setMail({email:'',message:''});
    

 }, function(error) {
    console.log('FAILED...', error);
    setError(error.text)
 });

    }
  return (
    <div>
      <footer className="footer">
    <div className="container">
        <div className="row">
            <div className="col-md-4">
                <h5><i className="fa fa-road"></i> PANORAMIC4U.COM</h5>
                <div className="row">
                    <div className="col-6">
                        <ul className="list-unstyled" style={{fontSize:'20px'}}>
                            <li><Link to="/bird-view">Bird Eye View</Link></li>
                            <li><Link to="/events">Events</Link></li>
                            <li><Link to="/obituaries">Obituaries</Link></li>
                            <li><Link to="/properties">Properties</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <h5 className="text-md-right" style={{fontSize:'24px'}}>Contact Us</h5>
                <hr/>
                <ul style={{paddingLeft:'0px', margin:'0px', listStyle:'none'}}>
                    <p style={{margin:'', fontSize:'22px'}}><i style={{marginRight:'15px'}} className="fa fa-phone"></i>+44 7877 416 041</p>
                    <p style={{margin:'', fontSize:'22px'}}><i style={{marginRight:'15px'}} className="fa fa-envelope"></i>info@panoramic4u.com</p>
                </ul>
            </div>
            <div className="col-md-5">
                <form  onSubmit={sendEmail}>
                    {error && <p>{error}</p>}
                    <fieldset className="form-group">
                        <input value={mail.email} onChange={(e)=>setMail({...mail,email:e.target.value})} type="email" required className="form-control" id="exampleInputEmail1" placeholder="Enter email"/>
                    </fieldset>
                    <fieldset className="form-group">
                        <textarea  value={mail.message} className="form-control"onChange={(e)=>setMail({...mail,message:e.target.value})} required id="exampleMessage" placeholder="Message"></textarea>
                    </fieldset>
                    <fieldset className="form-group text-xs-right">
                        <button type="submit" className="btn btn-secondary-outline btn-lg">Send</button>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</footer>
    </div>
  )
}

export default Footer