import React from 'react'
import "../css/header.css"
import Logo from "../images/logo.png"
const Header = () => {
  return (
    <div className="header">
      <div className="t-st">
        <h2 className="title">www.PANORAMIC4U.com</h2>
        <p className="subtitle">For Us By Us..</p>
      </div>
      <div><img className="logo" src={Logo}/></div>
    </div>
  )
}

export default Header