import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../firebase";
const Protected = ({ isLoggedIn, children,setIsLoggedIn }) => {
   
 if (!isLoggedIn) {
 return <Navigate to="/login" replace />;
 }
 return children;
};
export default Protected;