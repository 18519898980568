
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Homepage from './pages/Homepage';
import Dashboard from './components/Dashboard';
import Login from './pages/Login';
import  {AdminDataInput}  from './components/AdminDataInput';
import AdminCard from "./pages/AdminCard"
import Header from './components/Header';
import Footer from './components/Footer';
import  NavBar  from './components/Navbar';
import BirdViewPage from './pages/BirdViewPage';
import ObituariesPage from './pages/ObituariesPage';
import Register from './pages/Register';
import Protected from './pages/Protected';
import { useEffect, useState } from 'react';
import {auth} from './firebase'
import DevotionPage from './pages/DevotionPage';
import PropertiesPage from './pages/PropertiesPage';
import EventPage from './pages/EventPage';
import Posts from './components/Posts';
import { EditPost } from './components/EditPost';
function App() {
  const [isLoggedIn,setIsLoggedIn] =useState(false)
 
  return (

    <BrowserRouter>
    {/* <div style={{display:'flex',alignItems:'s',flexDirection:'column'}}> */}
    <Header/>
    <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/bird-view" element={<BirdViewPage />} />
      <Route path="/obituaries" element={<ObituariesPage />} />
      <Route path="/properties" element={<PropertiesPage />} />
      <Route path="/events" element={<EventPage />} />
      <Route path="/devotion" element={<DevotionPage />} />
      <Route path="/posts" element={
      <Protected isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}><Posts/></Protected>}/>
      <Route path="/admin" element={
      <Protected isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}><AdminDataInput/></Protected>}/>

<Route path="/post/:id" element={
      <EditPost/>}/>

      <Route path="post" element={<AdminCard/>}/>
      <Route path="signup" element={<Register/>}/>
<Route path="login" element={<Login setIsLoggedIn={setIsLoggedIn}/>}/>
    </Routes>
    <Footer/>
    {/* </div> */}
  </BrowserRouter>
  
  );
}

export default App;
