import React, { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import "../css/navbar.css";
import { auth, db } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
const NavBar = ({ setIsLoggedIn, isLoggedIn }) => {
  const [modal, setModal] = useState(false);
  const nav = useNavigate();
  let [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [input, setInput] = useState("");
  const handleSearch = () => {
    let filteredData = data.filter((item) => {
      return item.itemData.title.includes(input);
    });
    setFiltered(filteredData);
  };


  useEffect(() => {
    let dbData = [];
    db.collection("posts")
      .get()
      .then((data) => {
        data.forEach((item) => {
          let id = item.id;
          let itemData = item.data();
          dbData.push({ id, itemData });
        });
        setData(dbData);
      });
  }, []);

  const logout = () => {
    auth.signOut().then(() => {
      setIsLoggedIn(false);
      nav("/");
    });
  };
  return (
    <>
    <Navbar collapseOnSelect expand="xl" sticky>
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" style={{width:'100%', display:'flex',justifyContent:'space-evenly'}}>
          {/* <div className="navbar"> */}
        <Link to="/" className="navbar-links">
          Home
        </Link>
        <Link to="/bird-view" className="navbar-links">
          Bird Eye View
        </Link>
        <Link to="/properties" className="navbar-links">
          Properties
        </Link>
        <Link to="/events" className="navbar-links">
          Events
        </Link>
        <Link to="/devotion" className="navbar-links">
          Devotional
        </Link>
        <Link to="/obituaries" className="navbar-links">
          Obituaries
        </Link>
        {isLoggedIn &&<><Link to="/admin" className="navbar-links">
          Admin
        </Link><Link to="/posts" className="navbar-links">
          Posts
        </Link></> }
        <i
          class="fa-solid fa-magnifying-glass fa-2x"
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => setModal(!modal)}
        ></i>
        {isLoggedIn && <button className="login-btn" onClick={logout} >Logout</button>}
        {!isLoggedIn && <Link to={'/login'} className="navbar-links">Login</Link>}
      {/* </div> */}
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
      
      {modal && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            backgroundColor: "blue",
            padding: "50px",
            alignItems: "center",
            width: "100%",
            margintop: "20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <input
              type="text"
              onChange={(e) => setInput(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "16px",
                marginRight: "10px",
              }}
            />
            <button onClick={() => handleSearch()}>Search</button>
          </div>
          {filtered &&
            filtered.map((item, index) => {
              return (
                <Link to={`/post?id=${item.id}`}>
                  <li key={index} style={{ color: "white" }}>
                    {item.itemData.title}
                  </li>
                </Link>
              );
            })}
        </div>
      )}
    </>
  );
};

export default NavBar;
