import React, { useEffect, useState } from 'react'
import PageCard from '../components/PageCard'
import Pagination from '../components/Pagination'
import "../css/homepage.css"
import { db } from '../firebase'

const PropertiesPage = () => {
    const [items,setItems] = useState([])
   const [currentItems, setCurrentItems] = useState([]);
   useEffect(()=>{
    let data=[]
    db.collection('posts').where('type','==','properties').get().then(query=>{
        query.forEach(doc=>{
            let item = doc.data()
            let id =doc.id
            data.push({id,item})
        })
        setItems(data)
    }).catch(err=>{
        console.log(err)
    })
},[])
    return (<div style={{ display: 'flex', alignItems:"center",justifyContent: "center",flexDirection:'column',textAlign:'center',margin:"20px" }}>
           <h1 className="aboutus-title">Properties</h1>
            {currentItems.length>0?currentItems.map((item, index) => {
                return <PageCard item={item} />
            }):<p>No Post Found</p>}
            <Pagination  setCurrentItems={setCurrentItems} items={items}/>

        </div>
    )
}

export default PropertiesPage