import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {db} from "../firebase"
const Posts = () => {
    const [posts,setPosts] =useState([])
    useEffect(()=>{
        let dbPosts = []
        db.collection('posts').get().then(data=>{
            data.forEach(item=>{
                let id = item.id
dbPosts.push({id,...item.data()})
            })
            setPosts(dbPosts)
        })

    },[])
    console.log(posts)
  return (
    <Table striped bordered hover>
  <thead>
    <tr>
      <th>S no.</th>
      <th>Type</th>
      <th>Start Date</th>
      <th>Title</th>
      <th>Action</th>

    </tr>
  </thead>
  <tbody>
    {posts.length>0?posts.map((item,index)=>{
        return <tr>
        <td>{index+1}</td>
        <td>{item.type}</td>
        <td>{(item.startDate).split('-').reverse().join('-')}</td>
        <td>{item.title}</td>
        <Link to={`/post/${item.id}`}>Edit</Link>
  
      </tr>
    }):<h3>No Post Found</h3>}
   
  </tbody>
</Table>
  )
}

export default Posts