import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { db } from '../firebase';
const AdminCard = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [item,setItem]=useState({})
  const id = queryParams.get('id');
  useEffect(()=>{
    
    db.collection('posts').doc(id).get()
        .then(snapshot => setItem(snapshot.data()))
  },[id])
console.log(item)
  return (
    <div style={{justifyContent:"Center", display: 'flex'}}>
        <Card style={{margin:"10px",width:"60vw"}}>
            <Card.Body>
                <Card.Title style={{textAlign:"center", padding:"20px"}}>
                  <h1>{item.title}</h1>
                </Card.Title>
                <Card.Img variant="top" style={{width:"100%"}} src={item.image} />
                <Card.Text style={{fontSize:"24px", padding:"20px"}}>
                <p>{item.desciption}</p>
                {item.type==="events" && <p>Date: {(item.startDate).split('-').reverse().join('-')}</p>}
                {item.type==="events" && <p>Place: {item.place}</p> }
                {item.type==="events" && <p>Time: {item.time}</p> }
                {item.type==="events" && <p>Occasion: {item.occasion}</p> }
                {item.type==="events" && <p>Contact Info: {item.contact}</p> }
                {item.type==="properties" && <p>Address: {item.address}</p> }
                {item.type==="properties" && <p>Price/Rent: {item.rent}</p> }
                {item.type==="properties" && <p>Landmark: {item.landmark}</p> }
                {item.type==="properties" && <p>Contact Info: {item.more}</p> }
                {item.type==="obituaries" && <p>Date: {(item.startDate).split('-').reverse().join('-')}</p> }
                {item.type==="obituaries" && <p>Name: {item.name}</p> }
                {item.type==="obituaries" && <p>Contact Info: {item.contact}</p> }
                {item.type==="bird eye view" && <p>Date: {(item.startDate).split('-').reverse().join('-')}</p> }
                {item.type==="bird eye view" && <p>Marketing: {item.marketPlace}</p> }
                {item.type==="devotional" && <p style={{padding:'20px 0'}} >Date: {(item.startDate).split('-').reverse().join('-')}</p> }
                {item.type==="devotional" && <p style={{paddingBottom:'20px'}}>Today's Word: {item.today}</p> }
                {item.type==="devotional" && <p style={{paddingBottom:'20px'}}>Devotional: {item.devotion}</p> }
                {item.type==="devotional" && <p>Prayer: {item.prayer}</p> }
                {item.type==="properties" && <p>Landmark: {item.landmark}</p> }
                
{item.videoLink &&<iframe title="Video Link" controls src={'https://res.cloudinary.com/dvc56ayit/video/upload/v1654533145/My_Fitness_Journey_Round_Two___Weight_Loss_Finding_Balance_Building_Healthy_Habits_s7frdl.mp4'}  style={{width:"100%",height:'500px'}}>
  
  </iframe>}                
                </Card.Text>
            </Card.Body>
        </Card>
    </div>
  )
}

export default AdminCard