import React from 'react';
import { Card, Button } from "react-bootstrap";
import "../css/homepage.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from 'react-router-dom';
const CardTopFour = ({item}) => {
  return (
    <Card className="CardTopFour-card">
      <Card.Img variant="top" className="CardTopFour-card-img" src={item.itemData.image} />
      <Card.Body>
        <Card.Title><strong>{item.itemData.title}</strong></Card.Title>
        <Card.Text className='comment'>
          {item.itemData.desciption}
          </Card.Text>
        <Button variant="primary" > <Link style={{color:'white',textDecoration:'none'}}  to={`/post?id=${item.id}`}> Check Out </Link> </Button>
        
      </Card.Body>
    </Card>
  )
}

export default CardTopFour