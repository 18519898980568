import React, { useEffect, useState } from 'react'
import { auth } from '../firebase';
import {useNavigate } from 'react-router-dom';
const Login = ({setIsLoggedIn}) => {
  const nav = useNavigate()
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  function getMessageFromErrorCode(e) {
    switch (e.code.substr(5)) {
      case "ERROR_EMAIL_ALREADY_IN_USE":
      case "account-exists-with-different-credential":
      case "email-already-in-use":
        return "Email already used. Go to login page.";
        break;
      case "ERROR_WRONG_PASSWORD":
      case "wrong-password":
        return "Wrong email/password combination.";
        break;
      case "weak-password":
        return "Password should be at least 6 characters";
        break;
      case "ERROR_USER_NOT_FOUND":
      case "user-not-found":
        return "No user found with this email.";
        break;
      case "ERROR_USER_DISABLED":
      case "user-disabled":
        return "User disabled.";
        break;
      case "ERROR_TOO_MANY_REQUESTS":
      case "operation-not-allowed":
        return "Too many requests to log into this account.";
        break;
      case "ERROR_OPERATION_NOT_ALLOWED":
      case "operation-not-allowed":
        return "Server error, please try again later.";
        break;
      case "ERROR_INVALID_EMAIL":
      case "invalid-email":
        return "Email address is invalid.";
        break;
      default:
        return "Log in failed. Please try again.";
        break;
    }
  }
const [error,setError]=useState(null)
useEffect(()=>{
  auth.onAuthStateChanged(function(user) {
    if (user) {

      setIsLoggedIn(true)
      nav('/posts')
      // User is signed in.
    }else{
      console.log('no user')
    }
  });
},[])
  const handleSubmit =e=>{
    e.preventDefault();
    
    auth.signInWithEmailAndPassword(credentials.email,credentials.password).then(user=>{
      setIsLoggedIn(true)
      nav('/admin')
    }).catch(err=>{
      console.log(err)
      setError(getMessageFromErrorCode(err))
      
      setTimeout(()=>{
        setError(null)
      },2000)
      e.target.reset()
    })
  }
  return (
    
    <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100vw',height:'100vh',backgroundColor:'blue',flexDirection:'column'}}>
      <div style={{padding:'50px',border:'2px solid white',borderRadius:'10px',boxShadow:'5px 10px #FFFFFF',backgroundColor:'yellow'}}>
      {error && <p style={{fontSize:'1.5rem',color:'red',fontWeight:'200',margin:'10px'}}>{error}</p>}

      <form onSubmit={handleSubmit} style={{display:'flex',alignItems:'stretch',justifyContent:'space-evenly',flexDirection:'column'}}>
      <label htmlFor="email" style={{fontSize:'1.5rem',color:'blue',fontWeight:'200',margin:'10px'}}>Email : </label>
      <input
        type="email"
        required
        name="email"
        onChange={(e) =>
          setCredentials({ ...credentials, email: e.target.value })
        }
        style={{borderRadius:'10px'}}

      />
      <br/>
      <label htmlFor="password" style={{fontSize:'1.5rem',color:'blue',fontWeight:'200',margin:'10px'}}>Password : </label>
      <input
        type="password"
        required
        name="password"
        onChange={(e) =>
          setCredentials({ ...credentials, password: e.target.value })
        }
        style={{borderRadius:'10px'}}
      />
      <button type="submit" style={{margin:'5px',backgroundColor:'blue',fontSize:'1.5rem',color:'white',borderRadius:'10px'}}>Submit</button>
    </form>
      </div>
    </div>
  )
}

export default Login